.slick-next:before {
    color: #081f3e; /* Change this to the desired color */
    font-size: 30px;
}

.slick-prev:before {
    color: #081f3e; /* Change this to the desired color */
    font-size: 30px;
}

.slick-dots li button {
    width: 12px; /* Adjust the width and height as needed */
    height: 12px;
}

.slick-dots li button::before {
    font-size: 12px; /* Adjust the font size to control the dot size */
}

/* .slick-slide {
  margin-right: 20px !important;
}

.slick-slide:last-child {
  margin-right: 0 !important;
}  */

.your-carousel .carousel-item {
    margin: 20px !important; /* Adjust this value as needed to control the spacing */
}

/* Remove the right margin from the last slide content to avoid extra space */
.your-carousel .carousel-item:last-child {
    margin: 0 !important;
}