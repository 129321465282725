.mcq-container {
    padding: 3px;
}

.single-column {
    width: 100%; /* Single column takes full width */
}

.question {
    margin-bottom: 10px; /* Space between questions */
}

/*img {*/
/*    max-width: 500px;   !* Fixed width *!*/
/*    max-height: 15px;  !* Fixed height *!*/
/*    object-fit: cover; !* Ensures that images fit within the specified dimensions *!*/
/*}*/

.option-cell {
    padding: 1px 1px; /* Adjust padding for spacing */
}

.image-container {
    text-align: center; /* Centers the image horizontally */
    margin-top: 0; /* Ensures the image is at the very top */
}

.top-image {
    max-width: 100%; /* Ensures the image is responsive */
    height: 150px; /* Maintains aspect ratio */
    display: block; /* Prevents any inline spacing */
    margin: 0 auto; /* Centers the image horizontally */
    padding-top: 0;
}

@media print {
    .print-div {
        page-break-after: always; /* Forces each div to print on a new page */
    }

    /* Optional: Hide the print button when printing */
    .print-button {
        display: none;
    }
}
